import { PathHolder } from './path-utils';

const DATI_ENDPOINT = '/codoo-server';

export class DatiResources {
  constructor(public readonly datiId: string) {
    // empty
  }

  toUrl = (download: boolean, ...relPath: string[]) => {
    return DatiResources.encodeUrl_(this.datiId, PathHolder.from(relPath), download);
  };

  static encodeUrl = (datiId: string, relPath: string, download = false): string => {
    return DatiResources.encodeUrl_(datiId, PathHolder.from(relPath), download);
  };

  private static encodeUrl_ = (
    datiId: string,
    pathHolder: PathHolder,
    download = false,
  ): string => {
    const suffix = download ? '?download=true' : '';
    const path = pathHolder.segments.map((it) => encodeURIComponent(it)).join('/');
    return `${DATI_ENDPOINT}/d_/${datiId}/${path}${suffix}`;
  };
}

export function datiUrlBuilder<T extends string>(options: {
  datiId: string;
  currentFolderPath: string;
}) {
  const { datiId, currentFolderPath } = options;
  return (name: T, download = false) => {
    return DatiResources.encodeUrl(datiId, `${currentFolderPath}/${name}`, download);
  };
}
