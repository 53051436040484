import {
  type ApiHelper,
  ApiHelperWithData,
  type BaseRequest,
  splitParams,
} from '@ocodelib/api-common';
import type { PagerData, SysKey, SysKeyGrp } from '../model/index';

/**
 * SysKeyManage Api
 */
export class SysKeyManageApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 그룹 정보 조회
   */
  grpInfo = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
  }> => {
    const url = '/admin/sys-key/grp-info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 그룹 정보 및 그룹의 키 목록 조회
   */
  grpDetails = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/grp-details';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 그룹의 키 목록 조회
   */
  keysByGrp = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{ keyList: SysKey[] }> => {
    const url = '/admin/sys-key/keys-by-grp';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 그룹 업데이트 - 그룹의 이름과 그룹 ID를 변경
   * 그룹 ID가 변경되는 경우 SysKey 테이블의 grp도 모두 변경됩니다.
   */
  grpUpdate = (
    params: {
      oldGrp: string;
      newGrp: string;
      newGrpName: string;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
  }> => {
    const url = '/admin/sys-key/grp-update-grp';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 키 목록 조회회
   */
  searchKeys = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      grp?: string;
      key?: string;
      keyword?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<SysKey> }> => {
    const url = '/admin/sys-key/key-search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 그룹 목록 조회회
   */
  searchGroups = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      grp?: string;
      keyword?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<SysKeyGrp> }> => {
    const url = '/admin/sys-key/grp-search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  existsGrp = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{
    value: boolean;
  }> => {
    const url = '/admin/sys-key/grp-check-exists';
    return this.withData.post(url, ...splitParams(params));
  };

  insertKeys = (
    params: {
      grp: string;
      grpName: string;
      keyList: {
        key: string;
        keyName: string;
        keyCate?: string;
        tag?: string;
      }[];
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/insert-key-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  updateKeys = (
    params: {
      grp: string;
      grpName: string;
      keyList: {
        key: string;
        keyName: string;
        keyCate?: string;
        tag?: string;
      }[];
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/update-key-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  deleteGrp = async (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/sys-key/grp-delete';
    await this.helper.post(url, ...splitParams(params));
  };
}
