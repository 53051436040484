const HTTP_TOKEN_HEADER = 'x-custom-authorization';
const HTTP_REFRESH_TOKEN_HEADER = 'x-custom-refresh-token';
const HTTP_TOKEN_CLEAR_HEADER = 'x-custom-authorization-clear';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';
const apiBaseURL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://codoo.me/codoo-server';
const routerBaseURL = process.env.NEXT_PUBLIC_ROUTER_BASE_URL || '/';
const datiBaseURL = apiBaseURL;
const isProduction = ['ocode.kr', 'adm.ocode.kr'].some((it) => apiBaseURL.includes(it));
const DEBUG = false;

export const config = {
  isProduction,
  debug: DEBUG,
  storageKeys: {
    authRefreshToken: 'ocode_auth_refresh_token',
    authToken: 'ocode_auth_token',
    profile: 'ocode_auth_profile',
    browserId: 'ocode_browser',
    aiToken: 'ocode_ai_token',
  },
  httpHeaderKeys: {
    token: HTTP_TOKEN_HEADER,
    tokenClear: HTTP_TOKEN_CLEAR_HEADER,
    refreshToken: HTTP_REFRESH_TOKEN_HEADER,
  },
  apiBaseURL,
  baseURL,
  datiBaseURL,
  routerBaseURL,
  googleOauth2ClientId: '588818548399-6m2mhq261ih1v8ldij1mo96euentdb0i.apps.googleusercontent.com',
};
